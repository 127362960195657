.section {
    width: 100%;
    height: 200px;
    
}

.wrapper {
    width: 1280px;  
    height: 200px;
    margin: 0 auto;
    display: flex;
    transition: 0.2s all cubic-bezier(0.215, 0.610, 0.355, 1);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 55px;
}
.title {
    width: 100%;
    font-size: 48px;
    font-weight: bold;
        font-family: "Montserrat Alternates", sans-serif;
    text-align: left;
}
.button {
    min-width: 360px;
    background: #f5f5f525;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    transition: 0.2s all cubic-bezier(0.215, 0.610, 0.355, 1);
    font-family: "Montserrat Alternates", sans-serif;
    border: 3px solid #f5f5f5;
    border-radius: 50px;
    &:hover {
        background: #f5f5f5;
        border: 3px solid #f5f5f5;
        color: var(--accent);
    }
}
@media screen and (max-width: 1620px) {
    .wrapper {
        width: 1080px;
    }
}
@media screen and (max-width: 1280px) {
    .wrapper {
        width: 780px;
    }
    .title {
        font-size: 50px;
    }
}
@media screen and (max-width: 920px) {
    .section {
        height: auto;
        padding: 20px 0;
    }
    .wrapper {
        width: 100%;
        padding: 0 20px;
        flex-direction: column;
    }
    .title {
        text-align: center;
        font-size: 32px;
    }
    .button {
        min-width: 300px;
        text-align: center;
        font-size: 20px;
    }
}