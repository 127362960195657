.list {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    & li {
        display: flex;
        align-items: center;
        gap: 40px;
    }
}

.number {
    font-size: 64px;
    border-radius: 50%;
    border: 4px solid #f5f5f5;
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    user-select: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
}

.head {
    height: fit-content;
    margin-bottom: 25px;
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 52px;
    font-weight: bold;
}

.body {
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 32px;
}

.button {
    background: #f5f5f525;
    font-size: 36px;
    // max-width: 400px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    transition: 0.2s all cubic-bezier(0.215, 0.610, 0.355, 1);
    font-family: "Montserrat Alternates", sans-serif;
    border: 3px solid #f5f5f5;
    border-radius: 50px;
    &:hover {
        background: #f5f5f5;
        border: 3px solid #f5f5f5;
        color: var(--accent);
    }
}

.text {
    text-align: left;
}

@media screen and (max-width: 920px) {
    .list li {
        flex-direction: column;
    }
    .text {
        text-align: center;
    }
    .head {
        font-size: 32px;
    }
    .body,.button {
        text-align: center;
        font-size: 20px;
    }
}
