.title {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: baseline;
    align-items: center;
    gap: 15px;
    font-size: 62px;
}

.back {
    min-width: 70px;
    min-height: 70px;
    width: 70px;
    height: 70px;
    transition: 0.2s all ease-in-out;
    border: 4px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

br {
    margin: 0 0 30px 0;
}

.back:hover {
    background: #fff;
    color: var(--accent);
}

.head {
    font-size: 48px;
    font-weight: 600;
    font-family: "Montserrat alternates", sans-serif;
    margin-bottom: 10px;
    margin-top: 30px;
}

.body {
    width: 100%;
    font-size: 32px;
    font-family: "Montserrat alternates", sans-serif;
}

 /* The switch - the box around the slider */
 .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e2e2e2;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #e2e2e2;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #e2e2e2;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  } 

  .choose {
    display: flex;
    gap: 15px;
    & svg {
        transition: 0.3s all ease-in-out;
        color: #c0c0c0;
    }
    & .active {
        color: #fff !important;
    }
    margin-bottom: 15px;
  }

  
@media screen and (max-width: 920px) {
    table {
        width: 90%;
    }
    .back {
        min-width: 50px;
        min-height: 50px;
        width: 50px;
        height: 50px;
    }
    .title {
        font-size: 32px;
    }
    .head {
        font-size: 28px;
    }
    
    .body {
        font-size: 18px;
    }
}

table {
    margin: 40px 0 0 0;
    background: #fff;
    border-radius: 15px;
    color: var(--accent);
    width: 100%;
    display: table;
    table-layout: auto;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: .2);
    & td {
        padding: 15px;
        border-bottom: 2px solid var(--accent);
    }
    & th {
        padding: 15px;
        border-bottom: 3px solid var(--accent);
    }
    & tr :first-child {
        border-right: 2px solid var(--accent);
    }
    & tr {
        width: 100%;
        border: none;
        transition: 0.2s all ease-in-out;
    }
    & tr :last-child{
        text-align: center;
        border-left: 2px solid var(--accent);
    }
    & tbody tr:hover {
        background: var(--accent);
        color: #fff;
    }
    & .last td {
        border-bottom: 2px solid transparent !important;
    }
}
.add {
    width: 100%;
    font-size: 20px;
    color: #f5f5f5;
}