.item {
    background:  var(--accent);
    // background-blend-mode: color-dodge;
    // background: #34A853 radial-gradient(circle  at bottom right, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.36) 25%, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1) 90%, rgba(255,255,255,0) 100%) !important;
    display: flex;
    padding: 20px 40px 30px 40px;
    border-radius: 15px;
    flex-direction: row;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    justify-content: space-between;
    align-items: center;
    color: #fff;
    gap: 50px;
    &:hover .image {
        transform: scale(1);
    }
}

.text {
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.head {
    background: #ffffff28;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: fit-content;
    margin-bottom: 25px;
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 52px;
    font-weight: bold;
    border: 4px solid #fff;
    border-radius: 40px;
    margin: 10px auto;
    padding: 10px 20px;
}

.body {
    width: 100%;
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
}

@media screen and (max-width: 1280px) {
    .content {
        flex-direction: column;
    }
}
@media screen and (max-width: 920px) {
    .head {
        font-size: 32px;
    }
    .body {
        font-size: 20px;
    }
}
