.content {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 40px; 
}

.item {
    width: 80%;
    height: 120px;

    background: #fff;
    color: var(--accent);

    border-radius: 15px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: .1);


    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    text-align: center;

    font-family: "Montserrat alternates", sans-serif;
    font-size: 42px;
    font-weight: 500;
    justify-self: center;
}

@media screen and (max-width: 1280px) {
    .item {
        font-size: 32px;
    }
}

@media screen and (max-width: 920px) {
    .item {
        font-size: 26px;
    }
    .content {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }
}