.app {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.modal {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 500;
    gap: 20px;
    align-items: center;
    background: #fff;
    width: max-content;
    height: 320px;
    top: 50%;
    padding: 0 40px;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    color: #1c1c1c;
    & span {
        font-weight: 700;
        font-size: 32px !important;
    }
    & a {
        cursor: pointer;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: var(--accent);
        color: #fff;
        text-align: center;
        padding: 10px;
        transition: 0.2s all ease-in-out;
        border-radius: 15px;
        font-size: 24px;
        & svg {
            width: 25px;
            height: 25px;
        }
        &:hover {
            filter: brightness(0.8);
        }
    }
}

.me {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px
}

@media screen and (max-width: 920px) {
    .modal {
        width: 320px !important;
        & a {
            border-radius: 30px;
            font-size: 18px;
        }
    }
}