.head {
    font-weight: bold;
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 58px;
    margin-bottom: 25px;
}

.body {
    font-size: 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    & div {
        font-family: "Montserrat Alternates", sans-serif;
    }
}

.content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 70px;
}

.info {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.photo {
    width: 50%;
    // aspect-ratio: 0.6/1;
    height: 100%;
    background: #6e6e6e;
    border-radius: 15px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.button {
    background: var(--accent);
    font-size: 36px;
    // max-width: 400px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    padding: 10px 30px;
    transition: 0.2s all cubic-bezier(0.215, 0.610, 0.355, 1);
    font-family: "Montserrat Alternates", sans-serif;
    border: 3px solid #f5f5f5;
    border-radius: 50px;
    &:hover {
        background: #f5f5f5;
        border: 3px solid var(--accent);
        color: var(--accent);
    }
}

@media screen and (max-width: 1620px) {
    .body,.button {
        font-size: 30px;
    }
}
@media screen and (max-width: 1280px) {
    .head {
        font-size: 42px;
    }
    .body,.button {
        font-size: 24px;
    }
}
@media screen and (max-width: 920px) {
    .content {
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }
    .info,.photo {
        width: 100%;
        gap: 35px;
    }
    .body,.head {
        text-align: center;
    }
}