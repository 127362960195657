.header {
    width: 100%;
    max-height: 90px;
    background: #fff;
    color: #1c1c1c !important;
    box-shadow: 0 5px 20px rgba(0,0,0,.1);
    transition: 0.4s all cubic-bezier(0.215, 0.610, 0.355, 1);
    &.active {
        max-height: 100vh;
    }
}

.wrapper {
    margin: 0 auto;
    width: 1600px;
    height: 90px;
    display: flex;
    justify-content: space-between;
    transition: 0.4s all cubic-bezier(0.215, 0.610, 0.355, 1);
    align-items: center;
    flex-direction: row;
    z-index: 15;
}

.text {
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 5%;
    line-height: 32px;
    margin: 0 20px;
}

.mobile {
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    &> div:nth-child(1).active {
        transform: rotate(45deg) translateY(5px) translateX(5px);
    }
    &> div:nth-child(2).active {
        display: none;
    }
    &> div:nth-child(3).active {
        transform: rotate(-45deg) translateY(-5px) translateX(5px);
    }
}

.line {
    width: 50px;
    display: block;
    border: 2px solid var(--accent);
    background: #f5f5f5;
    border-radius: 5px;
    transition: 0.4s all cubic-bezier(0.215, 0.610, 0.355, 1);
}

.targets {
    display: flex;
    gap: 25px;
    font-weight: 400;
    font-size: 20px;
    & a {
        cursor: pointer;
        transition: 0.2s all cubic-bezier(0.215, 0.610, 0.355, 1);
        border-bottom: 2px solid transparent;
    }
    & a:hover {
        border-bottom: 2px solid #1c1c1c;
    }
}

.mobilemenu {
    width: 100%;
    height: 40%;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    transition: 0.2s opacity linear;
    flex-direction: column;
    gap: 25px;
    font-size: 26px;
    z-index: 2;
    opacity: 0;
    & a {
        display: none;
        width: 100%;
        padding: 30px 0;
        text-align: center;
        transition: 0.2s opacity cubic-bezier(0.215, 0.610, 0.355, 1);
        cursor: pointer;
    }
}
.menubutton {
    display: none;
    background: var(--accent);
    width: 100%;
    padding: 30px 0;
    filter: brightness(0.95);
    text-align: center;
    transition: 0.2s opacity cubic-bezier(0.215, 0.610, 0.355, 1);
    cursor: pointer;
}
.actived {
    opacity: 1 !important;
    & a , & .menubutton{
        display: block;
    }
}

.button {
    padding: 10px 20px;
    transition: 0.2s all cubic-bezier(0.215, 0.610, 0.355, 1);
    border: 3px solid var(--accent);
    background: var(--accent);
    color: #fff;
    border-radius: 30px;
    &:hover {
        background: #f5f5f5;
        color: var(--accent);
        transform: translateY(-5px);
    }
}

@media screen and (max-width: 1620px) {
    .wrapper {
        width: 1260px;
    }
}
@media screen and (max-width: 1280px) {
    .wrapper {
        width: 920px;
    }
}
@media screen and (max-width: 920px) {
    .wrapper {
        width: 100%;
        padding: 0 20px;
    }
    .targets, .button {
        display: none;
    }
    .mobile {
        display: flex;

    }
}