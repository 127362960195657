.content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;


    & a {
        width: 100%;
        padding: 15px;

        transition: 0.2s all ease-in-out;

        background: var(--accent);
        color: #fff;
        border-radius: 15px;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .1);

        font-size: 28px;

        display: flex;
        justify-content: space-between;

        &:hover {
            transform: translateX(20px);
        }
    }
}


@media screen and (max-width: 920px) {
    .content a{
        font-size: 20px;
    }
}

