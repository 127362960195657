* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
}

html {
  height: 100%;
  overflow: auto;
  font-size: 15px;
}

body {
  width: 100vw;
  height: auto;
  background: #f5f5f5;
  color: #ffffff;
  overflow-x: hidden;
}

#root {
  width: 100vw;
  height: auto;
  overflow-x: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .4);
}

.deactive {
  overflow: hidden;
}

:root {
  --accent: #34A853;
  --transition: 0.2s all cubic-bezier(0.165, 0.84, 0.44, 1);
}

ul,
ol,
nav,
menu {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
a {
  display: block;
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}
input,
button,
textarea,
select {
  font: inherit;
  border: none;
}
input{
  outline: none;
  outline-offset: 0;
}
input:active,
input:hover,
input:focus{
  outline: none;
  outline-offset: 0;
}
button {
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  padding: 0;
}
button:focus {
  outline: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

@import "~react-image-gallery/styles/css/image-gallery.css";