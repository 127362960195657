footer {
    min-height: 230px;
    background: #1E1E1E;
}

.content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brand {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.social {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 10px;
    & svg {
        transition:  0.2s all ease-in-out;
        width: 35px;
        height: 35px;
    }
    &:hover {
        color: var(--accent);
    }
}

.nav {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    & a {
        width: fit-content;
        position: relative;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        cursor: pointer;
        &::after {
            content: '';
            background: #fff;
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 0%;
            transition: 0.2s all linear;
            height: 1px;
        }
    }
    
    & a:hover  {
        &::after {
            width: 100%;
        }
    }
} 

@media screen and (max-width: 720px) {
    .content {
        flex-direction: column;
        gap: 30px;
    }
    .nav a {
        width: 100%;
        text-align: center;
    }
    .social {
        justify-content: center;
    }
}

.networks {
    display: flex;
    justify-content: center;
    gap: 20px;
}