.section {
    width: 100%;
    min-height: 400px;
}

.wrapper {
    width: 1280px;  
    height: 100%;
    margin: 40px auto;
    display: flex;
    transition: 0.2s all cubic-bezier(0.215, 0.610, 0.355, 1);
    flex-direction: column;
    align-items: center;
    gap: 55px;
}

.title {
    width: 100%;
    font-size: 62px;
    font-weight: bold;
        font-family: "Montserrat Alternates", sans-serif;
    text-align: left;
}

.primary {
    background: #f5f5f5 !important;
    color: #1d1d1d !important;
}

.green {    
    color: #f5f5f5 !important;
    background: var(--accent) !important;
    background: var(--accent) radial-gradient(circle  at bottom left, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.36) 25%, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1) 90%, rgba(255,255,255,0) 100%) !important;
}

.secondary {
    width: 90% !important;
    margin: 0 auto !important;
    border-radius: 25px !important;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, .2);
}
@media screen and (max-width: 1620px) {
    .wrapper {
        width: 1080px;
    }
}
@media screen and (max-width: 1280px) {
    .wrapper {
        width: 780px;
    }
    .title {
        font-size: 50px;
    }
}
@media screen and (max-width: 920px) {
    .wrapper {
        width: 100%;
        padding: 0 20px;
    }
    .title {
        text-align: center;
        font-size: 32px;
    }
    .secondary {
        width: calc(100% - 40px) !important;
    }
}