.bg {
    // background: var(--accent) radial-gradient(circle  at bottom left, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.36) 25%, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1) 90%, rgba(255,255,255,0) 100%) !important;
    // box-shadow: inset 0 0 10px rgba(0, 0, 0, .2);
}

.welcome {
    color: #1c1c1c;
    // text-shadow: 0 0 5px rgba(0, 0, 0, .2);
    width: 100%;
    text-align: center;
    font-weight: 900;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 72px;
}

.button {
    background: var(--accent);
    font-size: 30px;
    max-width: 400px;
    padding: 10px 20px;
    transition: 0.2s all cubic-bezier(0.215, 0.610, 0.355, 1);
    font-family: "Montserrat Alternates", sans-serif;
    border: 3px solid #f5f5f5;
    border-radius: 30px;
    font-weight: normal;
    &:hover {
        background: #f5f5f5;
        border: 3px solid var(--accent);
        color: var(--accent);
    }
}

.place {
    color: var(--accent);
}

.buttons {
    display: flex;
    gap: 30px;
}

@media screen and (max-width: 920px) {
    .welcome {
        font-size: 58px;
    }
    .buttons {
        flex-direction: column;
    }
    .button {
        width: 100%;
    }
    
}

@media screen and (max-width: 480px) {
    .welcome {
        font-size: 48px;
    }
    .buttons {
        flex-direction: column;
    }
    
}