.logo {
    height: 100%;
    display: flex;
    align-items: center;
    display: flex;
    gap: 10px;
    align-items: center;
    & img {
        width: 65px;
        height: 65px;
    }
}

.name {
    font-family: "Roboto",sans-serif;
    font-weight: 500;
    font-size: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}


.page {
    font: "Roboto";
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.cross {
    display: block;
    width: 1px;
    border: 1.5px solid #fff;
    height: 65px;
    // background: #fff;
    border-radius: 50px;
}

@media screen and (max-width: 920px) {
    .cross {
        display: none;
    }
    .logo {
        gap: 5px;
    }
    .name {
        font-size: 20px;
    }
    .page {
        font-size: 16px;
    }
}